import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet primer té forma ovoide obrint-se després però mai del tot. Aquest té una altura de 3-8 cm i 6-13 cm de diàmetre, presenta uns solcs des de l’àpex cap a baix amb petites esquametes entre ells i és de color grisenc a gris terrós. Làmines blanques, lliures, atapeïdes que tornen de color negre amb la caiguda de les espores. El peu és cilíndric, de 5-14 x 1-2 cm, blanc, amb un anell fugaç que només es veu als exemplars més joves. Les espores són negres en massa, llises, el·líptiques, de 7,5-10 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      